import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.classList.toggle('dark', this.isDark)
  }

  toggle() {
    this.isDark = !this.isDark
    this.element.classList.toggle('dark', this.isDark)
  }

  // private
  
    get isDark() {
      return JSON.parse(this.localPref || this.systemIsDark) === true
    }

    set isDark(value) {
      return localStorage["bryanwithay-dark-theme"] = value
    }

    get localPref() {
      return localStorage["bryanwithay-dark-theme"]
    }

    get systemIsDark() {
      return window.matchMedia("(prefers-color-scheme: dark)").matches
    }

}
